@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flex-divider {
    border-top-width: 1px;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 17 83% 54%;
    --primary-foreground: 0 0% 100%;
    --primary-hover: 20 84% 61%;

    --secondary: 0 0% 86%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --gray: 0, 0%, 35%;
    --gray2: 0, 0%, 46%;
    --white: 0, 0%, 100%;
    --black: 0 0% 0%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --ub-lightblue: 198 86% 46%;
    --ub-background: 0 0 97.3;
    --ub-card-border: 0 0 96%;
    --ub-highlight-blue: 198 81.7% 47.3%;
    --ub-highlight-teal: 178 100% 34.7%;
    --ub-highlight-darkteal: 180 100% 24%;
    --ub-highlight-green: 75 46.5% 52.4%;

    --radius: 1.25rem;
  }
  html {
    scroll-padding-top: 160px;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.hover-content {
  visibility: hidden;
  /*transition: visibility 0.5s ease;*/
}

.hover-content:has(~ .hover-initiator:hover),
.hover-initiator:hover ~ .hover-content {
  visibility: visible;
}

.hover-content:hover {
  visibility: visible;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow:
    rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
    rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
    rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
    rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 51 !important;
}
